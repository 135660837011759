import React from "react"
import theme from "theme"
import { Theme, Text, Section, Box } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | Eventful Horizons</title>
        <meta
          name={"description"}
          content={"Зробіть свої моменти незабутніми"}
        />
        <meta property={"og:title"} content={"Головна | Eventful Horizons"} />
        <meta
          property={"og:description"}
          content={"Зробіть свої моменти незабутніми"}
        />
        <meta
          property={"og:image"}
          content={"https://ua.kexonovstart.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://ua.kexonovstart.com/img/839888.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="180px 0 384px 0"
        background="linear-gradient(180deg,rgba(0, 0, 0, 0.63) 0%,rgba(0, 0, 0, 0.54) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://ua.kexonovstart.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box"
        sm-padding="120px 0 124px 0"
        quarkly-title="Hero-15"
      >
        <Text
          margin="0px 0px 30px 0px"
          text-align="center"
          color="--light"
          font="normal 400 18px/1.5 --fontFamily-sans"
          letter-spacing="1px"
        >
          Зробіть свої моменти незабутніми
        </Text>
        <Text
          margin="0px 0px 50px 0px"
          text-align="center"
          font="normal 700 78px/1.2 --fontFamily-sans"
          letter-spacing="1px"
          color="--light"
          padding="0px 50px 0px 50px"
          lg-padding="0px 0 0px 0"
          md-font="normal 700 42px/1.2 --fontFamily-sans"
          sm-font="normal 700 36px/1.2 --fontFamily-sans"
        >
          Eventful Horizons
        </Text>
        <Text
          margin="0px 0px 0px 0px"
          color="#c8ced8"
          text-align="center"
          padding="0px 50px 0px 50px"
          font="--lead"
          lg-padding="0px 0 0px 0"
        >
          Місце, де ваші мрії про ідеальну подію стають реальністю. Незалежно
          від того, чи хочете ви організувати грандіозне весілля, вражаючу
          корпоративну вечірку або інтимне святкування, ми гарантуємо, що кожна
          деталь буде виконана з елегантністю та точністю. З нами ваша подія не
          тільки виправдає ваші очікування, але й перевершить їх.
        </Text>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="80%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Про нас
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Eventful Horizons спеціалізується на створенні незабутніх вражень з
            урахуванням ваших унікальних потреб. Наша віддана команда привносить
            досвід, ентузіазм і досконалість у кожну подію, гарантуючи, що кожна
            подія запам'ятається з усіх правильних причин. Від розробки
            концепції до реалізації, наші професіонали невтомно працюють, щоб
            втілити ваше бачення в життя.
          </Text>
        </Box>
      </Section>
      <Section
        padding="120px 0 130px 0"
        lg-padding="80px 0 90px 0"
        quarkly-title="Stages/Steps-4"
      >
        <Text
          margin="0px 0px 90px 0px"
          font="normal 600 42px/1.2 --fontFamily-sans"
          color="--darkL1"
          border-color="--color-dark"
          text-align="center"
          lg-margin="0px 0px 50px 0px"
        >
          Чому обирають нас?
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(4, 1fr)"
          md-grid-template-columns="1fr"
          md-grid-template-rows="auto"
          md-grid-gap={0}
          lg-padding="0px 0 0px 0"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            padding="0px 30px 30px 0"
            sm-padding="0px 0 34px 0"
            lg-padding="0px 25px 0 0"
            md-margin="0px 0px 30px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
              <Text
                margin="0 0 15px 25px"
                border-color="--color-light"
                color="--darkL1"
                font="normal 500 22px/1.2 --fontFamily-sans"
                text-align="left"
              >
                Персоналізована увага:
              </Text>
              <Text
                margin="0 0 0 25px"
                font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
                color="#65696d"
                text-align="left"
                border-color="#7a7c7f"
              >
                Ми пишаємося тим, що надаємо персоналізовані послуги, які
                відповідають саме вашим уподобанням та очікуванням.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            padding="0px 30px 30px 0"
            sm-padding="0px 0 34px 0"
            lg-padding="0px 25px 0 0"
            md-margin="0px 0px 30px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
              <Text
                margin="0 0 15px 25px"
                border-color="--color-light"
                color="--darkL1"
                font="normal 500 22px/1.2 --fontFamily-sans"
                text-align="left"
              >
                Неперевершена креативність:
              </Text>
              <Text
                margin="0 0 0 25px"
                font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
                color="#65696d"
                text-align="left"
                border-color="#7a7c7f"
              >
                Наша креативна команда мислить нестандартно, щоб запропонувати
                свіжі, інноваційні ідеї, які підносять будь-яку подію.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            padding="0px 30px 30px 0"
            sm-padding="0px 0 0 0"
            lg-padding="0px 25px 0 0"
            md-margin="0px 0px 30px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
              <Text
                margin="0 0 15px 25px"
                border-color="--color-light"
                color="--darkL1"
                font="normal 500 22px/1.2 --fontFamily-sans"
                text-align="left"
              >
                Надійне партнерство:
              </Text>
              <Text
                margin="0 0 0 25px"
                font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
                color="#65696d"
                text-align="left"
                border-color="#7a7c7f"
              >
                Ми співпрацюємо з перевіреними постачальниками, щоб забезпечити
                якість та безперебійну інтеграцію послуг.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            padding="0px 30px 30px 0"
            sm-padding="0px 0 0 0"
            lg-padding="0px 25px 0 0"
            md-margin="0px 0px 30px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
              <Text
                margin="0 0 15px 25px"
                border-color="--color-light"
                color="--darkL1"
                font="normal 500 22px/1.2 --fontFamily-sans"
                text-align="left"
              >
                Планування без стресу:
              </Text>
              <Text
                margin="0 0 0 25px"
                font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
                color="#65696d"
                text-align="left"
                border-color="#7a7c7f"
              >
                З нами планування заходів не викликає жодних труднощів. Ми
                подбаємо про всі деталі, щоб ви могли зосередитися на тому, щоб
                насолоджуватися святом.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
